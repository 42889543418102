
import Vue from "vue";
import { mapGetters } from "vuex";
import StripeModal from "@/components/Modals/StripeModal.vue";
//@ts-ignore
import _ from "lodash";
import CategoryComponent from "@/components/Partials/CategoryComponent.vue";
import Fuse from "fuse.js";
export default Vue.extend({
  name: "SignUpUserInformationMentor",
  components: {
    StripeModal,
    CategoryComponent
    // StripePaymentMethods
  },
  data() {
    return {
      data: {
        Expertise: [] as Array<string>,
        Title: [],
        // Specialization: [],
        Institution: [],
        location: "",
        language_ids: [],
        currency_id: "",
        timezone_id: ""
      } as any,
      errors: {} as any,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      },
      btnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      paymentMethods: "stripePayment/getPaymentMethods",
      languages: "helper/getLanguages",
      categories: "category/getCategories",
      timezones: "helper/getTimezones",
      locations: "helper/getLocations"
    })
  },
  created(): void {
    this.$store.dispatch("stripePayment/GET_PAYMENT_METHODS");
    this.$store.dispatch("category/GET_CATEGORIES");
    this.$store.dispatch("helper/GET_LANGUAGES");
    this.$store.dispatch("helper/GET_TIMEZONES");
    this.mapKeys();
  },
  methods: {
    onSearch(search: any, loading: any) {
      if (search.length >= 3) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading: any, search: any, vm: any) => {
      vm.$store.dispatch("helper/GET_LOCATIONS", search).then(() => {
        loading(false);
      });
    }, 150),
    async addPaymentMethod() {
      this.$bvModal.show("stripe");
      //@ts-ignore
      await this.$refs.stripe.loadStripe();
    },
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("mentor/UPDATE_INFORMATION", this.data)
        .then(() => {
          this.btnLoading = false;
          // (this as any).$router.push("/register/privacy");
          this.$router.push("/profile");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    mapKeys() {
      this.data.location = this.profile.location;
      this.data.timezone_id = this.profile.timezone_id;
      this.data.language_ids = this.profile.language_ids;
      if (this.profile?.category_types) {
        for (const i of Object.keys(this.profile.category_types)) {
          if (i in this.data) {
            this.data[i] = this.profile.category_types[i];
          }
        }
      }
    },
    addCategory(type: any, category: string) {
      this.data[type].push(category);
    },
    deleteCategory(type: any, index: number) {
      this.data[type].splice(index, 1);
    },
    fuseSearch(options: any, search: any) {
      const fuse = new Fuse(options, {
        keys: ["name", "native_name"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }: any) => item)
        : options;
    }
  }
});
