
import Vue from "vue";
import ClipperModal from "@/components/ClipperModal.vue";

export default Vue.extend({
  name: "SignUpProfile",
  components: {
    ClipperModal
  },
  props: {
    profile: Object
  },
  data() {
    return {
      url: "",
      btnLoading: false,
      data: {
        first_name: "",
        last_name: "",
        image: "",
        short_description: "",
        website: "",
        bio: "",
        social_links: {
          instagram: "",
          facebook: "",
          linkedin: "",
          twitter: ""
        }
      } as any,
      errors: {} as any
    };
  },
  mounted(): void {
    this.mapKeys();
  },
  methods: {
    showImageUpload() {
      this.$bvModal.show("clipper");
    },
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("mentor/UPDATE_PROFILE", this.data)
        .then(() => {
          this.btnLoading = false;
          this.data.image = "";
          this.$router.push("/register/information");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    mapKeys() {
      for (const i of Object.keys(this.data)) {
        if (i === "image") {
          // this.data[i] = this.profile.profile_pic
        } else if (i in this.profile) {
          //@ts-ignore
          this.data[i] = this.profile[i] ? this.profile[i] : "";
        } else if (i === "social_links") {
          if (this.profile?.full_social_links?.length) {
            for (const url of this.profile?.full_social_links) {
              this.data[i][url.name] = url.url;
            }
          }
          // this.data[i] = this.profile.all_social_links;
        }
      }
      this.url = this.profile.profile_link;
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
