
import Vue from "vue";
import SignUpProfileMentor from "@/components/SignUp/SignUpProfileMentor.vue";
import SignUpProfileMentee from "@/components/SignUp/SignUpProfileMentee.vue";
import SignUpUserInformationMentor from "@/components/SignUp/SignUpUserInformationMentor.vue";
import SignUpPrivacy from "@/components/SignUp/SignUpPrivacy.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SignUpInfo",
  components: {
    SignUpProfileMentor,
    SignUpProfileMentee,
    SignUpUserInformationMentor,
    SignUpPrivacy
  },
  props: {
    registrationItem: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stepIcons: [
        {
          name: "Profile",
          icon: "add-user-bold",
          filled: true,
          visible: true
        },
        {
          name: "User Information",
          icon: "document-bold",
          filled: this.registrationItem === "user-information", //||
          //this.registrationItem === "privacy",
          visible: false
        }
        // {
        //   name: "Privacy",
        //   icon: "privacy-icon",
        //   filled: this.registrationItem === "privacy",
        //   visible: true
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  watch: {
    profile() {
      if (this.profile && this.profile.id) {
        this.checkProfile();
      }
    }
  },
  created() {
    this.checkProfile();
  },
  methods: {
    checkProfile() {
      const userInfoIcon = this.stepIcons.find(
        el => el.name === "User Information"
      );
      if (userInfoIcon && this.profile && this.profile.type === "mentor") {
        userInfoIcon.visible = true;
      }
    }
  }
});
