
import Vue from "vue";
import { mapGetters } from "vuex";
import ClipperModal from "@/components/ClipperModal.vue";
//@ts-ignore
import _ from "lodash";
import Fuse from "fuse.js";

export default Vue.extend({
  name: "SignUpProfileMentee",
  components: {
    ClipperModal
    // StripeModal,
    // StripePaymentMethods
  },
  props: {
    profile: Object
  },
  data() {
    return {
      url: "",
      nextBtnLoading: false,
      data: {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        image: "",
        location: "",
        timezone_id: "",
        language_ids: []
      } as any,
      errors: {} as any,
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      languages: "helper/getLanguages",
      timezones: "helper/getTimezones",
      locations: "helper/getLocations"
    })
  },
  mounted(): void {
    this.mapKeys();
  },
  created(): void {
    this.$store.dispatch("helper/GET_LANGUAGES");
    this.$store.dispatch("helper/GET_TIMEZONES");
  },
  methods: {
    onSearch(search: any, loading: any) {
      if (search.length >= 3) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading: any, search: any, vm: any) => {
      vm.$store.dispatch("helper/GET_LOCATIONS", search).then(() => {
        loading(false);
      });
    }, 150),
    async addPaymentMethod() {
      this.$bvModal.show("stripe");
      //@ts-ignore
      await this.$refs.stripe.loadStripe();
    },
    showImageUpload() {
      this.$bvModal.show("clipper");
    },
    setTempFile(data: any) {
      this.url = data.url;
      this.data.image = data.temp;
    },
    onSubmit() {
      this.nextBtnLoading = true;

      this.$store
        .dispatch("mentee/UPDATE_PROFILE", this.data)
        .then(() => {
          this.nextBtnLoading = false;
          this.data.image = "";
          // this.$router.push("/register/privacy");
          this.$router.push("/profile");
        })
        .catch(error => {
          this.nextBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    mapKeys() {
      for (const i of Object.keys(this.data)) {
        if (i === "image") {
          // this.data[i] = this.profile.profile_pic
        } else if (i in this.profile) {
          //@ts-ignore
          this.data[i] = this.profile[i] ? this.profile[i] : "";
        } else if (i === "social_links") {
          this.data[i] = this.profile.all_social_links;
        }
      }
      this.url = this.profile.profile_link;
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    fuseSearch(options: any, search: any) {
      const fuse = new Fuse(options, {
        keys: ["name", "native_name"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }: any) => item)
        : options;
    }
  }
});
